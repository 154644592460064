/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLink } from '../../../hook'
import useStyles from './account-profile-style'
import {
  Layout,
  Loading,
  Button,
  SEO,
} from '../../../ui'
import {
  ApplyReferralCodeView,
  ProfileFormView,
  ProfileInfoView,
  UpdatePasswordFormView,
  ProfileSocialLogin,
} from './components'
import Modal from '../../../ui/modal/modal-view';

function AccountProfileView(props) {
  const {
    countryCallingCodeOptions,
    enableBirthdayEdit,
    enableApplyReferralCode,
    genderSelection,
    showEmailVerificationCodeInput,
    showVerificationCodeInput,
    user,
    requiredFields,
    formFields,
    formDisabled,
    formShowing,
    pageReady,
    seoTitle,
    onDeleteUser,
    onDisplayForm,
    onHideForm,
    onRequestEmailTokenSuccess,
    onRequestEmailTokenError,
    onRequestSmsTokenSuccess,
    onRequestSmsTokenError,
    localeOptions,
    socialAvailable,
    supportedLoginApproaches,
    onSocialSignIn,
  } = props
  const { navigate } = useLink()
  const [
    isDeleteAccountConfirmationDialogOpen,
    setIsDeleteAccountConfirmationDialogOpen,
  ] = useState(false)
  const styles = useStyles()
  const { t } = useTranslation()

  const handleDeleteUser = () => {
    setIsDeleteAccountConfirmationDialogOpen(true)
  }
  const onRequestClose = () => {
    setIsDeleteAccountConfirmationDialogOpen(false)
  }
  const onConfirmDeleteAccountClick = async () => {
    await onDeleteUser()
    navigate('/')
  }
  const editFormProps = {
    t,
    countryCallingCodeOptions,
    localeOptions,
    enableBirthdayEdit,
    genderSelection,
    user,
    requiredFields,
    formFields,
    formDisabled,
    showEmailVerificationCodeInput,
    showVerificationCodeInput,
    supportedLoginApproaches,
    onCancel: onHideForm,
    onRequestEmailTokenSuccess,
    onRequestEmailTokenError,
    onRequestSmsTokenSuccess,
    onRequestSmsTokenError,
  }
  const updatePasswordFormProps = {
    t,
    formDisabled,
    onCancel: onHideForm,
  }
  return (
    <Layout login user={user}>
      <SEO title={seoTitle} />
      {
        !pageReady ? (
          <Loading />
        ) : (
          <div className={styles.container}>
            <div className={styles.boxInfo}>
              <div className={styles.header}>
                <h1 className={styles.title}>
                  {t('screens.account.profile.info.profile')}
                </h1>
                <p className={styles.intro}>
                  {t('screens.account.profile.info.message')}
                </p>
              </div>
              {
                _.isEqual(formShowing, 'updatePassword')
                && (
                  <UpdatePasswordFormView {...updatePasswordFormProps} />
                )
              }
              {
                _.isEqual(formShowing, 'editProfile') && (
                  <ProfileFormView {...editFormProps} />
                )
              }
              {
                _.isEqual(formShowing, 'applyReferralCode') && (
                  <ApplyReferralCodeView
                    t={t}
                    user={user}
                    onCancel={onHideForm}
                  />
                )
              }
              {
                _.isEmpty(formShowing) && (
                  <ProfileInfoView
                    onEditClick={() => { onDisplayForm('editProfile') }}
                    onDeleteClick={handleDeleteUser}
                    localeOptions={localeOptions}
                    t={t}
                    user={user}
                  />
                )
              }
            </div>
            {
              _.isEmpty(formShowing) && (
                <div className={styles.sideButtons}>
                  <div className={styles.sideButtonsInner}>
                    {
                      _.some(socialAvailable, ['available', true]) && (
                        <>
                          <p className={styles.sideButtonsLabel}>{t('screens.account.profile.info.socialLogin')}</p>
                          <div>
                            {
                              _.map(socialAvailable,
                                (social) => (
                                  <ProfileSocialLogin
                                    connectedSocialLoginProviders={user.connectedSocialLoginProviders}
                                    social={social}
                                    onSocialSignIn={onSocialSignIn}
                                    t={t}
                                    key={social.name}
                                  />
                                ))
                            }
                          </div>
                        </>
                      )
                    }
                    {

                      _.some(supportedLoginApproaches, ['verificationType', 'password'])
                      && (
                        <div className={styles.sideContent}>
                          <p className={styles.sideButtonsLabel}>{t('screens.account.profile.info.password')}</p>
                          <Button
                            dark
                            className={styles.sideButton}
                            text={t('screens.account.profile.buttons.password')}
                            onClick={() => { onDisplayForm('updatePassword') }}
                          />
                        </div>
                      )
                    }
                    {
                      enableApplyReferralCode && (
                        <div className={styles.sideContent}>
                          <p className={styles.sideButtonsLabel}>{t('screens.account.profile.info.applyReferralCode')}</p>
                          <Button
                            dark
                            className={styles.sideButton}
                            text={t('screens.account.profile.buttons.applyReferralCode')}
                            onClick={() => { onDisplayForm('applyReferralCode') }}
                          />
                        </div>
                      )
                    }
                  </div>
                </div>
              )
            }
          </div>
        )
      }
      <Modal
        dialog
        isOpen={isDeleteAccountConfirmationDialogOpen}
        onRequestClose={onRequestClose}
      >
        <div className={styles.dialogContainer}>
          <div className={styles.dialogBody}>
            <h1 className={styles.dialogTitle}>{t('screens.account.profile.deleteAccountDialog.title')}</h1>
            <p className={styles.dialogDescription}>{t('screens.account.profile.deleteAccountDialog.description')}</p>
          </div>
          <div className={styles.dialogButtons}>
            <Button
              className={styles.button}
              dark
              text={t('screens.account.profile.buttons.deleteUser')}
              onClick={onConfirmDeleteAccountClick}
            />
            <Button
              className={styles.button}
              border
              text={t('screens.account.profile.buttons.cancel')}
              onClick={onRequestClose}
            />
          </div>
        </div>
      </Modal>
    </Layout>
  )
}

export default AccountProfileView
