import React from 'react'
import _ from 'lodash'
import moment from 'moment/min/moment-with-locales'
import useStyles from './profile-info-style'
import { Button } from '../../../../../ui'
import combineClassNames from '../../../../../helpers/combineClassNames'

const ProfileInfoView = ({
  t,
  user = {},
  onEditClick,
  onDeleteClick,
  localeOptions,
}) => {
  const {
    name,
    alias,
    email,
    dateOfBirth,
    gender,
    locale,
    phoneData,
    salutation,
  } = user
  const styles = useStyles()
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        {
          (!_.isEmpty(salutation) && salutation !== 'unspecified') && (
            <dl className={styles.row}>
              <dt className={styles.label}>
                {t('screens.account.profile.info.salutation')}
              </dt>
              <dd className={styles.value}>
                {t('screens.account.profile.form.salutation.options', { context: salutation })}
              </dd>
            </dl>
          )
        }
        <dl className={styles.row}>
          <dt className={styles.label}>
            {t('screens.account.profile.info.name')}
          </dt>
          <dd className={styles.value}>{name}</dd>
        </dl>
        <dl className={styles.row}>
          <dt className={styles.label}>
            {t('screens.account.profile.info.screenName')}
          </dt>
          <dd className={styles.value}>{alias}</dd>
        </dl>
        <dl className={styles.row}>
          <dt className={styles.label}>
            {t('screens.account.profile.info.email')}
          </dt>
          <dd className={styles.value}>{email}</dd>
        </dl>
        <dl className={styles.row}>
          <dt className={styles.label}>
            {t('screens.account.profile.info.phone')}
          </dt>
          <dd className={styles.value}>{_.get(phoneData, 'formatted')}</dd>
        </dl>
        <dl className={styles.row}>
          <dt className={styles.label}>
            {t('screens.account.profile.info.dateOfBirth')}
          </dt>
          <dd className={styles.value}>
            {
              _.isEmpty(dateOfBirth)
                ? t('screens.account.profile.info.dateOfBirthEmpty')
                : moment(dateOfBirth).format(t('screens.account.profile.info.dateOfBirthDisplayFormat', 'LL'))
            }
          </dd>
        </dl>
        {
          (!_.isEmpty(gender) && gender !== 'unspecified') && (
            <dl className={styles.row}>
              <dt className={styles.label}>
                {t('screens.account.profile.info.gender')}
              </dt>
              <dd className={styles.value}>
                {t('screens.account.profile.form.gender.options', { context: gender })}
              </dd>
            </dl>
          )
        }
        {
          _.size(localeOptions) > 1
          && (
            <dl className={styles.row}>
              <dt className={styles.label}>
                {t('screens.account.profile.info.locale')}
              </dt>
              <dd className={styles.value}>{ _.isEmpty(locale) ? 'Not Set' : t('ui.locale', { context: locale })}</dd>
            </dl>
          )
        }

      </div>
      <div className={styles.actions}>
        <Button
          dark
          className={styles.button}
          text={t('screens.account.profile.buttons.edit')}
          onClick={onEditClick}
        />
        <Button
          className={combineClassNames([styles.button, styles.buttonDelete])}
          alert
          text={t('screens.account.profile.buttons.delete')}
          onClick={onDeleteClick}
        />
      </div>
    </section>
  )
}

export default ProfileInfoView
