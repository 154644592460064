import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  profileForm: {
    paddingTop: '1rem',
    // '& a': {
    //   display: 'block',
    //   fontSize: '1.4rem',
    //   color: theme.primary,
    //   marginTop: '1.6rem',
    //   marginBottom: '1.6rem',
    //   lineHeight: 1.14,

    // },
    // '& > span': {
    //   color: theme.greyLight,
    //   margin: 0,
    //   lineHeight: 1.14,
    // },
    // '& hr': {
    //   margin: '3rem 0',
    // },
    // '& > div + button': {
    //   marginTop: '1.6rem',
    // },
  },
  title: {
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.25,
    color: theme.colors.primary,
    marginBottom: '0.6rem',
  },
  // label: {
  //   fontSize: '1.2rem',
  //   fontWeight: 600,
  //   lineHeight: 1.25,
  // },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: `-${theme.gap}rem`,
  },
  col: {
    width: '100%',
    marginRight: `${theme.gap}rem`,
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      maxWidth: '32rem',
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: '50%',
    },
  },
  getSmsButtonContainer: {
    marginTop: '1.6rem',
  },
  asInputStyle: {
    textAlign: 'left',
    textTransform: 'none',
    fontSize: '1.2rem',
    fontWeight: 600,
    lineHeight: 1.25,
    letterSpacing: '-0.03em',
    paddingBottom: '0.8rem',
    margin: 0,
  },
  genderContainer: {
    marginTop: '2.4rem',
  },
  boxGender: {
    marginTop: '0.8rem',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: '3rem',
    '& > div': {
      marginRight: '2rem',
      position: 'relative',
    },
  },
  buttons: {
    marginTop: '2.4rem',
  },
  button: {
    marginBottom: '1rem',
  },
  dropdown: {
    width: '100%',
    marginTop: '2.4rem',
    // marginBottom: '3rem',
    minHeight: '3.6rem',
    '& label': {
      top: '-3rem',
      textTransform: 'uppercase',
      textAlign: 'left',
      fontSize: '1.2rem',
      fontWeight: 600,
      lineHeight: 1.25,
      letterSpacing: '-0.03em',
      paddingBottom: '0.8rem',
    },
  },
}))

export default useStyles
