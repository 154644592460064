/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Formik } from 'formik'
import URI from 'urijs'
import { useTranslation } from 'react-i18next'
import {
  cancelRequest,
  useAddresses,
  useAuth,
  useSystemSettings,
  useUser,
  useOmnitechApp,
} from 'react-omnitech-api'
import {
  useAccount,
  useAlert,
  useAnalytics,
  useCart,
  useLink,
  useI18n,
  useThemeConfig,
} from '../../../hook'
import { withAuthenticableNavigationBlock } from '../../../ui'
import {
  getUpdateProfileFormInitialValues,
  getSocialMediaAvailable,
  transformRegistrationFormValidationSchema,
  transformUpdatePasswordFormValidationSchema,
} from '../../../helpers'
import AccountProfileView from './account-profile-view'

function AccountProfileController(props) {
  // prepare hook
  const { location, pathContext } = props

  // Ref
  const formRef = useRef(null)

  const alert = useAlert()
  const { t } = useTranslation()
  const { navigate } = useLink()
  const { fetchCountries } = useAddresses()
  const {
    deleteSession,
    auth,
    deleteAuthenticationsByProvider,
    setAuth,
  } = useAuth()
  const {
    user,
    loyaltyInformation,
    updateUser,
    deleteUser,
    resetUser,
    createUsersOmniAuthOtps,
  } = useUser()
  const { getSupportedLoginApproaches } = useAccount()
  const { createCart, resetCart } = useCart()
  const { getSystemSetting } = useSystemSettings()
  const { getConfig } = useThemeConfig()
  const { availableLocales } = useI18n()
  const { api } = useOmnitechApp()
  const { trackEvent } = useAnalytics()

  // hook with dependencies
  const genderSelection = getSystemSetting('account.gender_selection', [])
  const salutationSelection = getSystemSetting('account.salutation_selection', [])
  const enableBirthdayEdit = getSystemSetting('account.enable_birthday_edit', false)
  const formConfig = getSystemSetting('account.user_edit_profile_fields', {})
  const enableApplyReferralCode = getConfig('config.pages.profile.enableApplyReferralCode', true)
  const supportedLoginApproaches = getSupportedLoginApproaches()

  // TODO get array all social sign in enable
  const socialAvailable = getSocialMediaAvailable(getSystemSetting)

  // internal states
  const [countriesEntities, setCountriesEntities] = useState([])
  const [defaultCountryCallingCode, setDefaultCountryCallingCode] = useState('')
  const [updateUserInProgress, setUpdateUserInProgress] = useState(false)
  const [formDisabled, setFormDisabled] = useState(true)
  const [pageReady, setPageReady] = useState(false)
  const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(false)
  const [showEmailVerificationCodeInput, setShowEmailVerificationCodeInput] = useState(false)
  const [alerts, setAlerts] = useState('')
  const [formShowing, setFormShowing] = useState('')

  // local variable
  const seoTitle = t('screens.accountProfile.seo.title')

  // TODO: change password
  // TODO: upload avatar
  // TODO: (Add-on) connect facebook

  /**
   * handleError
   * show error message via alert
   */
  function handleError(error) {
    const validationError = _.get(error, 'validationError.data.user.errors')
    if (
      !_.isEmpty(validationError)
      && _.isFunction(formRef.current.setFieldError)
    ) {
      _.each(validationError, (err, field) => {
        formRef.current.setFieldError(field, _.get(err, 'fullMessage', []).join('; '))
      })
    }
    const generalError = _.get(error, 'generalError', {})
    alert.show(generalError.message)
  }

  // locale options
  const localeOptions = _.map(availableLocales, (availableLocale) => ({
    value: availableLocale,
    label: t('ui.locale', { context: availableLocale }),
  }))

  // salutation options
  const salutationOptions = _.map(_.omitBy(salutationSelection, _.isNil), (salutation) => ({
    value: salutation,
    label: t('screens.account.profile.form.salutation.options', { context: salutation }),
  }))

  const fieldOptions = useMemo(() => ({
    gender: genderSelection,
    salutation: salutationOptions,
    locale: localeOptions,
  }), [genderSelection, salutationSelection, localeOptions])

  // transform the countries to usable format in select
  const countryCallingCodeOptions = useMemo(() => (
    countriesEntities.map((country) => ({
      label: country.callingCode,
      value: country.callingCode,
    }))
  ), [countriesEntities])

  const requiredFields = useMemo(() => (
    _.reduce(formConfig, (result, current, key) => {
      const newResult = result
      if (_.get(current, 'required', false)) {
        newResult[key] = true
        if (_.get(current, 'requireConfirmation', false)) {
          newResult[`${key}Confirmation`] = true
        }
      }
      return newResult
    }, {})
  ), [formConfig])

  const formFields = useMemo(() => {
    const sortedFields = _.sortBy( // sort rows
      _.reduce(formConfig, (result, value, key) => {
        if (
          (key === 'locale' && _.size(fieldOptions.locale) < 1)
          || (key === 'dateOfBirth' && !enableBirthdayEdit && !_.isEmpty(_.get(user, 'dateOfBirth')))
        ) {
          return result
        }
        const options = fieldOptions[key]
        result.push({
          ...value,
          fieldName: key,
          rowGroup: value.rowGroup || _.uniqueId('field_'),
          ...(_.isEmpty(options) ? {} : { options }),
        })
        return result
      }, []),
      ['displaySequence', 'rowGroup'],
    )
    const groups = _.groupBy(
      sortedFields,
      'rowGroup',
    )
    return _.reduce(
      sortedFields,
      (result, field) => {
        // prevent duplicate
        if (
          !_.isEmpty(
            _.find(
              _.flatten(result),
              { rowGroup: field.rowGroup },
            ),
          )
        ) {
          return result
        }
        result.push( // add grouped row to result
          _.sortBy( // sort columns
            groups[field.rowGroup] || [field],
            ['displaySequence', 'rowGroup'],
          ),
        )
        return result
      },
      [],
    )
  }, [
    formConfig,
    fieldOptions,
    enableBirthdayEdit,
    showVerificationCodeInput,
  ])

  /**
   * fetchCountriesApi
   * function to call api and fetch countries for country calling code options
   */
  const fetchCountriesApi = useCallback(async () => {
    try {
      const fetchCountriesApiQuery = getSystemSetting('api.v2.addresses.countries.registration.ecom.query', {})
      const { countries } = await fetchCountries({
        params: {
          pageSize: 999,
          ...fetchCountriesApiQuery,
        },
        arrayFormat: 'brackets',
      })
      const firstCountry = _.first(countries)
      setCountriesEntities(countries)
      setDefaultCountryCallingCode(firstCountry.callingCode)
      setPageReady(true)
    } catch (error) {
      handleError(error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSystemSetting, fetchCountries])

  // useMemo for caching variables
  const formInitialValues = useMemo(() => (
    formShowing === 'updatePassword'
      ? {
        password: '',
        currentPassword: '',
        passwordConfirmation: '',
      }
      : getUpdateProfileFormInitialValues({ formConfig, userData: user, defaultCountryCallingCode })
  ), [defaultCountryCallingCode, formConfig, user, formShowing])

  const formValidationSchema = useMemo(() => {
    if (formShowing === 'updatePassword') {
      return transformUpdatePasswordFormValidationSchema()
    }
    const formConfigWithInitVal = _.transform(formConfig, (result, value, key) => {
      result[key] = {
        ...value,
        initialValue: _.get(formInitialValues, key),
      }
    }, {})
    return transformRegistrationFormValidationSchema(formConfigWithInitVal)
  }, [formConfig, formShowing, formInitialValues])

  function handleDisplayForm(type) {
    setFormShowing(type)
  }

  function handleHideForm() {
    alert.remove()
    setShowEmailVerificationCodeInput(false)
    setShowVerificationCodeInput(false)
    setFormShowing('')
  }

  /**
   * Start delete user
   * @param {*} event
   */
  async function handleDeleteUser() {
    setPageReady(false)
    try {
      await deleteUser(user.id)
    } catch (error) {
      handleError(error)
    }

    try {
      deleteSession()
    } catch (error) {
      handleError(error)
    } finally {
      setAuth({
        ...auth,
        authToken: '',
        userId: '',
      })
      resetUser()
      resetCart()
      createCart({})
      setPageReady(true)
    }
  }

  // Handle EmailTokenButton events
  const onRequestEmailTokenSuccess = () => {
    setShowEmailVerificationCodeInput(true)
  }
  const onRequestEmailTokenError = (error) => {
    handleError(error)
  }

  // Handle SmsTokenButton events
  const onRequestSmsTokenSuccess = () => {
    setShowVerificationCodeInput(true)
  }
  const onRequestSmsTokenError = (error) => {
    handleError(error)
  }

  /**
   * handleEditProfile
   *
   * @param {*} values, object contain all value from input
   */
  async function handleEditProfile(values) {
    alert.remove()

    const userProfileFields = _.without(_.keys(formConfig), 'affiliatedUserMembershipCode')
    const optimizedValues = {
      ..._.pick(values, userProfileFields),
      ...(values.gender === '' ? { gender: null } : {}),
      ...(
        !_.isEmpty(values.phone)
        && !_.isEmpty(values.token)
          ? { smsToken: _.get(values, 'token', '') }
          : {}
      ),
      ...(
        !_.isEmpty(values.email)
        && !_.isEmpty(values.emailToken)
          ? { emailToken: _.get(values, 'emailToken', '') }
          : {}
      ),
    }

    const updatedValues = _.pickBy(optimizedValues, (value, key) => {
      const initVal = _.get(formInitialValues, key)
      return (
        // reject if no change
        !_.isEqual(value, initVal)
        // reject if both inital value and new value are null/undefined/empty string
        && !(
          (_.isNil(initVal) || _.isEqual(initVal, '')) && (_.isNil(value) || _.isEqual(value, ''))
        )
      )
    })

    // prepare api call payload
    const data = {
      userId: user.id,
      user: updatedValues,
      // approach: registrationApproach,
    }

    await updateUser(data)
  }

  /**
   * handleUpdatePassword
   *
   * @param {*} values, object contain all value from input
   */
  async function handleUpdatePassword(values) {
    alert.remove()

    // prepare api call payload
    const data = {
      userId: user.id,
      user: values,
    }

    await updateUser(data)
  }

  /**
   * handleApplyReferralCode
   *
   * @param {*} values, object contain all value from input
   */
  async function handleApplyReferralCode(values) {
    alert.remove()

    // prepare api call payload
    const data = {
      userId: user.id,
      user: _.pick(values, 'affiliatedUserMembershipCode'),
    }

    await updateUser(data)
  }

  /**
   * handleSubmit
   *
   * Formik onSubmit callback
   *
   * @param {*} values form values from Formik
   * @param {*} actions includes an object containing a subset of the injected props and methods
   */
  const handleSubmit = async (values, actions) => {
    alert.remove()
    setUpdateUserInProgress(true)
    const updatedValues = _.get(formRef, 'current.values', {})
    try {
      switch (formShowing) {
        case 'updatePassword':
          await handleUpdatePassword(updatedValues)
          break;
        case 'editProfile':
          await handleEditProfile(updatedValues)
          break;
        case 'applyReferralCode':
          await handleApplyReferralCode(updatedValues)
          // show success message
          alert.show(t('screens.accountReferral.shareCode.copySuccessMessage'), 'success')
          break;
        default:
          break;
      }
      handleHideForm()
    } catch (error) {
      handleError(error)
    } finally {
      setUpdateUserInProgress(false)
      actions.setSubmitting(false)
    }
  }

  /**
   * onSocialSignIn
   *
   * redirect facebook
   * TODO implement logical for other sacial platforms
   */

  async function handleSocialSignIn(provider) {
    const isConnect = _.includes(user.connectedSocialLoginProviders, provider)
    alert.remove()
    const url = new URI(location.href)
    // use a cross-platform application like NGROK to test in localhost
    const urlRedirect = `${url.protocol()}://${url.host()}/${pathContext.locale}/oauth/connect`

    // prepare api call payload
    const data = {
      omniAuthOtp: {
        provider,
        mode: 'redirect',
        redirect: false,
        redirectUrl: urlRedirect,
      },
    }

    // calling api for create session and control the flow of page redirect
    try {
      let response = null
      if (isConnect) {
        response = await deleteAuthenticationsByProvider(provider)
        window.location.reload()
      } else {
        response = await createUsersOmniAuthOtps(data)
        const urlAPI = _.get(api, 'host')
        const urlAuth = _.get(response.omniAuthOtp, 'requestPhasePath')
        const newUrl = `${urlAPI}${urlAuth}`
        // handle redirect to different page after sign in successfully
        navigate(
          newUrl,
          { replace: false },
        )
      }
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)

      setFormDisabled(false)

      throw error
    }
  }

  useEffect(() => {
    try {
      // track view profile
      trackEvent('customerViewProfile', {}, {
        user: _.pick(user, ['email', 'phone', 'firstName', 'lastName']),
      })
    } catch (ex) {
      // do nothing
    }
  }, [])

  useEffect(() => {
    alert.show(alerts)
  }, [alerts])

  useEffect(() => {
    if (!_.isEmpty(_.get(location, 'state.errorMessage'))) {
      setAlerts(_.get(location, 'state.errorMessage'))
    }
  }, [location])

  /**
   * get countries for country call code option
   */
  useEffect(() => {
    fetchCountriesApi()

    return () => {
      cancelRequest.cancelAll(['fetchCountries'])
    }
  }, [fetchCountriesApi])

  useEffect(() => {
    setFormDisabled(
      _.isEmpty(countriesEntities)
      || updateUserInProgress,
    )
  }, [countriesEntities, updateUserInProgress])

  const formPorps = {
    countryCallingCodeOptions,
    localeOptions,
    enableBirthdayEdit,
    formDisabled,
    genderSelection,
    formFields,
    requiredFields,
    showEmailVerificationCodeInput,
    showVerificationCodeInput,
    supportedLoginApproaches,
  }

  const viewProps = {
    pageReady,
    defaultCountryCallingCode,
    user,
    loyaltyInformation,
    enableApplyReferralCode,
    seoTitle,
    socialAvailable,
    formShowing,
    onSocialSignIn: handleSocialSignIn,
    onDeleteUser: handleDeleteUser,
    onDisplayForm: handleDisplayForm,
    onHideForm: handleHideForm,
    onRequestEmailTokenSuccess,
    onRequestEmailTokenError,
    onRequestSmsTokenSuccess,
    onRequestSmsTokenError,
    ...formPorps,
  }

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={formInitialValues}
      validateOnChange
      validationSchema={formValidationSchema}
      onSubmit={handleSubmit}
    >
      <AccountProfileView
        {...viewProps}
      />
    </Formik>
  )
}

export default withAuthenticableNavigationBlock(AccountProfileController)
