import _ from 'lodash'
import React from 'react'
import {
  Form,
  useFormikContext,
} from 'formik'
import {
  Button,
  Input,
} from '../../../../../ui'
import {
  useModal,
} from '../../../../../hook'
import useStyles from './apply-referral-code-style'

const ApplyReferralCodeView = (props) => {
  const {
    t,
    user,
    onCancel,
  } = props
  const {
    isSubmitting,
    isValid,
    values,
    submitForm,
    // resetForm,
  } = useFormikContext() || {}
  const modal = useModal()

  // useEffect(() => (() => { resetForm() }), [])
  const affiliatedUserMembershipCode = _.get(user, 'affiliatedUserMembershipCode')
  const formDisabled = !_.isEmpty(affiliatedUserMembershipCode)
  const isTouched = !_.isEmpty(_.get(values, 'affiliatedUserMembershipCode', ''))

  const handleSubmitForm = () => {
    modal.open({
      title: t('screens.account.profile.applyReferralCode.modalDialog.confirmApplyCode.title'),
      message: t('screens.account.profile.applyReferralCode.modalDialog.confirmApplyCode.description'),
      buttons: [
        {
          text: t('screens.account.profile.applyReferralCode.modalDialog.confirmApplyCode.buttons.confirm'),
          onClick: () => {
            submitForm()
            modal.close()
          },
        },
        {
          text: t('screens.account.profile.applyReferralCode.modalDialog.confirmApplyCode.buttons.cancel'),
          onClick: () => modal.close(),
        },
      ],
    })
  }
  const styles = useStyles()
  return (
    <div className={styles.profileForm}>
      <h2 className={styles.title}>{t('screens.account.profile.applyReferralCode.title')}</h2>
      <p className={styles.description}>{t('screens.account.profile.applyReferralCode.description')}</p>
      <Form noValidate>
        <div className={styles.row}>
          <div className={styles.col}>
            <Input
              formik
              label={t('screens.account.profile.form.affiliatedUserMembershipCode')}
              name="affiliatedUserMembershipCode"
              value={affiliatedUserMembershipCode}
              type="text"
              disabled={formDisabled}
              required
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Button
                className={styles.button}
                dark
                uppercase
                text={t('screens.account.profile.buttons.applyReferralCode')}
                disabled={isSubmitting || !isValid || formDisabled || !isTouched}
                onClick={handleSubmitForm}
              />
            </div>
            <div className={styles.col}>
              <Button
                className={styles.button}
                uppercase
                border
                text={t('screens.account.profile.buttons.cancel')}
                onClick={onCancel}
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default ApplyReferralCodeView
