import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import {
  ErrorMessage,
  Form,
  useFormikContext,
} from 'formik'
import {
  BirthdayInput,
  Button,
  Dropdown,
  Input,
  InputPhone,
  InputRadio,
  TokenButton,
} from '../../../../../ui'
import {
  useThemeConfig,
} from '../../../../../hook'
import {
  customStylesLanguageDropdown,
} from '../../../../../ui/dropdown/custom-styles'
import useStyles from './profile-form-style'
import { combineClassNames } from '../../../../../helpers'

const ProfileFormView = (props) => {
  const {
    countryCallingCodeOptions,
    enableBirthdayEdit,
    // genderSelection,
    t,
    requiredFields = {},
    formFields,
    formDisabled,
    showEmailVerificationCodeInput,
    showVerificationCodeInput,
    supportedLoginApproaches,
    // user,
    onRequestEmailTokenSuccess,
    onRequestEmailTokenError,
    onRequestSmsTokenSuccess,
    onRequestSmsTokenError,
    onCancel,
    // localeOptions,
  } = props
  const {
    dirty,
    errors,
    isSubmitting,
    isValid,
    setFieldValue,
    submitForm,
    resetForm,
    values,
    initialValues,
  } = useFormikContext() || {}
  const { getConfig } = useThemeConfig()
  const birthdayInputFormat = getConfig('config.pages.profileEditForm.birthdayInputFormat')

  useEffect(() => (() => { resetForm() }), [])

  const smsTokenDisable = useMemo(() => (
    _.get(values, 'localPhoneNumber') === _.get(initialValues, 'localPhoneNumber')
    || _.isEmpty(_.get(values, 'localPhoneNumber')) // Disable when user is removing phone number
    || _.has(errors, 'phone')
  ), [errors, values, initialValues])
  const emailTokenDisable = useMemo(() => (
    _.get(values, 'email') === _.get(initialValues, 'email')
    || _.isEmpty(_.get(values, 'email')) // Disable when user is removing email
    || _.has(errors, 'email')
  ), [errors, values, initialValues])

  const handleBirthdayChange = (value) => {
    setFieldValue('dateOfBirth', value)
  }

  const handlePhoneChange = ({ countryCallingCode, localPhoneNumber }) => {
    setFieldValue('countryCallingCode', countryCallingCode)
    setFieldValue('localPhoneNumber', localPhoneNumber)
    const phone = !_.isEmpty(countryCallingCode) && !_.isEmpty(localPhoneNumber)
      ? `+${countryCallingCode}${localPhoneNumber}`
      : ''
    setFieldValue('phone', phone)
  }

  const handleSubmitForm = () => {
    submitForm()
  }
  const styles = useStyles()

  const renderField = (field) => {
    // const phoneSupportedLoginApproaches =
    //   _.filter(supportedLoginApproaches, ['inputType', 'phone'])
    // const enableSmsToken = !_.isEmpty(phoneSupportedLoginApproaches)
    const emailSupportedLoginApproaches = _.filter(supportedLoginApproaches, ['inputType', 'email'])
    const enableEmailToken = _.some(emailSupportedLoginApproaches, ['verificationType', 'emailToken'])
    switch (field.fieldName) {
      case 'phone':
        return (
          <>
            <InputPhone
              formik
              labelClassName={styles.label}
              label={t('screens.account.profile.form.phone')}
              countryCallingCodeOptions={countryCallingCodeOptions}
              defaultValue={{
                countryCallingCode: initialValues.countryCallingCode,
                localPhoneNumber: _.replace(initialValues.phone, `+${initialValues.countryCallingCode}`, ''),
              }}
              name="phone"
              required={requiredFields.phone || _.every(supportedLoginApproaches, { inputType: 'phone' })}
              onChange={handlePhoneChange}
            />
            <div className={styles.getSmsButtonContainer}>
              <TokenButton
                dark
                disabled={smsTokenDisable}
                phone={`${_.get(values, 'countryCallingCode', '')}${_.get(values, 'localPhoneNumber', '')}`}
                onSuccess={onRequestSmsTokenSuccess}
                onError={onRequestSmsTokenError}
              />
            </div>
            {
              showVerificationCodeInput && (
                <div className={styles.row}>
                  <div className={styles.col}>
                    <Input
                      formik
                      labelClassName={styles.label}
                      label={t('screens.account.profile.form.token')}
                      name="token"
                      required={showVerificationCodeInput}
                    />
                  </div>
                </div>
              )
            }
          </>
        )
      case 'dateOfBirth':
        return (
          <BirthdayInput
            className={styles.boxBirthday}
            label={t('screens.account.profile.form.birthday')}
            name="dateOfBirth"
            required={requiredFields.dateOfBirth}
            inputFormat={birthdayInputFormat}
            onChange={handleBirthdayChange}
            disabled={!enableBirthdayEdit}
            defaultValue={initialValues.dateOfBirth}
          />
        )
      case 'gender':
        return (
          <div className={styles.genderContainer}>
            <p className={styles.asInputStyle}>{t(`screens.account.profile.form.${field.fieldName}.title`)}</p>
            <div className={styles.boxGender}>
              {
                _.map(field.options, (item) => {
                  if (_.isNull(item)) return <></>
                  return (
                    <InputRadio
                      key={`${field.fieldName}-${item}`}
                      formik
                      id={`registration-${field.fieldName}-${item}`}
                      label={t(`screens.account.profile.form.${field.fieldName}.options`, { context: item })}
                      name={field.fieldName}
                      value={item}
                    />
                  )
                })
              }
              <ErrorMessage name="gender" />
            </div>
          </div>
        )
      case 'locale':
      case 'salutation':
        return (
          <Dropdown
            label={
              t(
                `screens.account.profile.form.${field.fieldName}.title`,
                t(`screens.account.profile.form.${field.fieldName}`), // fallback
              )
            }
            className={styles.dropdown}
            placeholder={
              _.get(
                _.find(field.options, { value: _.get(initialValues, field.fieldName) }),
                'label',
                '',
              )
            }
            customStyles={customStylesLanguageDropdown}
            options={field.options}
            onChange={(event) => {
              setFieldValue(field.fieldName, _.get(event, 'value'))
            }}
            isSearchable={false}
            values={
              _.find(field.options, { value: _.get(values, field.fieldName) })
            }
          />
        )
      case 'email':
        return (
          <>
            <Input
              formik
              labelClassName={styles.label}
              label={t('screens.account.profile.form.email')}
              placeholder=" "
              name="email"
              type="email"
              required={requiredFields.email || _.every(supportedLoginApproaches, { inputType: 'email' })}
            />
            {
              enableEmailToken && (
                <>
                  <div className={styles.getSmsButtonContainer}>
                    <TokenButton
                      dark
                      disabled={emailTokenDisable}
                      email={_.get(values, 'email', '')}
                      onSuccess={onRequestEmailTokenSuccess}
                      onError={onRequestEmailTokenError}
                    />
                  </div>
                  {
                    showEmailVerificationCodeInput && (
                      <div className={styles.row}>
                        <div className={styles.col}>
                          <Input
                            formik
                            labelClassName={styles.label}
                            label={t('screens.account.profile.form.token')}
                            name="emailToken"
                            required={showEmailVerificationCodeInput}
                          />
                        </div>
                      </div>
                    )
                  }
                </>
              )
            }
          </>
        )
      case 'affiliatedUserMembershipCode':
        // ignore affiliatedUserMembershipCode
        return null
      default:
        return (
          <Input
            formik
            labelClassName={styles.label}
            label={t(`screens.account.profile.form.${field.fieldName}`)}
            name={field.fieldName}
            required={requiredFields[field.fieldName]}
          />
        )
    }
  }

  return (
    <div className={styles.profileForm}>
      <h2 className={styles.title}>{t('screens.account.profile.form.title')}</h2>
      <Form noValidate autoComplete="off">
        {
          _.map(formFields, (row, rowIndex) => (
            <div key={`row_${rowIndex}`} className={styles.row}>
              {
                _.map(row, (field, colIndex) => (
                  <div key={`col_${colIndex}`} className={styles.col}>
                    {renderField(field)}
                  </div>
                ))
              }
            </div>
          ))
        }
        <div className={combineClassNames([styles.row, styles.buttons])}>
          <div className={styles.col}>
            <Button
              className={styles.button}
              dark
              text={t('screens.account.profile.buttons.save')}
              disabled={isSubmitting || !isValid || !dirty || formDisabled}
              onClick={handleSubmitForm}
            />
          </div>
          <div className={styles.col}>
            <Button
              className={styles.button}
              border
              text={t('screens.account.profile.buttons.cancel')}
              onClick={onCancel}
            />
          </div>
        </div>
      </Form>
    </div>
  )
}

export default ProfileFormView
